var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDisplaySettings, useRecordComponent } from 'src/hooks/component';
import { collectionTableCanEditSelector, collectionTableHasSearchbarSelector, collectionTableSelectedRecordIdsState, collectionTableTypeSelector, tableDisplaySettingsState, tableSearchTextState, } from 'src/state';
import { CollectionTableType, CollectionType, } from 'src/shared';
import { toast } from 'react-toastify';
import BulkActionButtonOptions from '../OptionHeader/BulkEdit/BulkActionButtonOptions';
import TableFiltersRow from '../OptionHeader/TableFiltersRow';
import TableOptionHeader from '../OptionHeader/TableOptionHeader';
import ReverseCollectionViewOptions from '../OptionHeader/Views/ReverseCollectionViewOptions';
import StandardCollectionExtraHeaderOptions from '../OptionHeader/ExtraOptions/StandardCollectionExtraHeaderOptions';
var TableOptionHeaderFromReverseCollection = function (_a) {
    var _b;
    var viewId = _a.viewId, collectionId = _a.collectionId, referenceCollectionId = _a.referenceCollectionId, referenceRecordId = _a.referenceRecordId, reverseRelationId = _a.reverseRelationId, schemaId = _a.schemaId, standardRelation = _a.standardRelation, standardCollectionComponent = _a.standardCollectionComponent, moreOptionsDropdown = _a.moreOptionsDropdown, colorCoding = _a.colorCoding, schedulePublishShift = _a.schedulePublishShift;
    var displaySettings = useRecoilValue(tableDisplaySettingsState(reverseRelationId));
    var setSearchText = useSetRecoilState(tableSearchTextState(reverseRelationId));
    var onCreateNewRecord = useRecordComponent().onCreateNewRecord;
    var applyDisplaySettingsToView = useDisplaySettings().applyDisplaySettingsToView;
    var collectionType = useRecoilValue(collectionTableTypeSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var canAddNewRecord = useRecoilValue(collectionTableCanEditSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var onSearch = useCallback(function (searchQuery) {
        if (searchQuery) {
            setSearchText(searchQuery);
        }
        else {
            setSearchText('');
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var handleAddNewRecord = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onCreateNewRecord('', collectionId, undefined, referenceCollectionId, referenceRecordId, schemaId, standardCollectionComponent)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [
        collectionId,
        onCreateNewRecord,
        referenceCollectionId,
        referenceRecordId,
        schemaId,
        standardCollectionComponent,
    ]);
    var handleApplyFilters = useCallback(function (filters, shouldSave) { return __awaiter(void 0, void 0, void 0, function () {
        var filterProperties, hasDuplicates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    filterProperties = filters.map(function (f) { return f.property; });
                    hasDuplicates = new Set(filterProperties).size < filterProperties.length;
                    if (hasDuplicates) {
                        toast.error('Only one filter per property is allowed', {
                            position: 'bottom-center',
                        });
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, applyDisplaySettingsToView(reverseRelationId, { filters: filters }, shouldSave, false)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, reverseRelationId]);
    var handleApplySortEntries = useCallback(function (sortBy) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, applyDisplaySettingsToView(reverseRelationId, { sortBy: sortBy }, true, false)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, reverseRelationId]);
    var handleApplySchemaOptions = useCallback(function (schemaOptions) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, applyDisplaySettingsToView(reverseRelationId, { schemaOptions: schemaOptions }, true, false)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, reverseRelationId]);
    var handleGroupBySetting = useCallback(function (groupSetting) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, applyDisplaySettingsToView(reverseRelationId, { groupSetting: groupSetting }, true, false)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, reverseRelationId]);
    var selectedRecordIds = useRecoilValue(collectionTableSelectedRecordIdsState(reverseRelationId));
    var canSearch = useRecoilValue(collectionTableHasSearchbarSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var canBulkEdit = (schemaId || standardRelation) && canAddNewRecord && selectedRecordIds.length > 0;
    return (_jsx(_Fragment, { children: _jsx(TableOptionHeader, { viewId: viewId, collectionId: collectionId, displaySettings: displaySettings, addNewRecord: canAddNewRecord && collectionType !== CollectionType.TASK
                ? handleAddNewRecord
                : undefined, applySort: !standardCollectionComponent ? handleApplySortEntries : undefined, applySchemaOptions: handleApplySchemaOptions, applyGroupBySetting: handleGroupBySetting, searchRecord: canSearch ? onSearch : undefined, bulkEditOptions: canBulkEdit ? (_jsx(BulkActionButtonOptions, { reverseRelationId: reverseRelationId, collectionId: collectionId })) : undefined, collectionViews: _jsx(ReverseCollectionViewOptions, { reverseRelationId: reverseRelationId }), tableFilters: _jsx(TableFiltersRow, { filters: displaySettings.filters, reverseCollectionProps: {
                    reverseCollectionViewId: viewId,
                    recordId: referenceRecordId,
                    schemaId: schemaId,
                    standardRelation: standardRelation,
                    standardCollectionComponent: standardCollectionComponent,
                }, applyFilter: handleApplyFilters, collectionId: collectionId, currentGroupBySchemaId: (_b = displaySettings.groupSetting) === null || _b === void 0 ? void 0 : _b.schema, canApplyFilter: true }), extraOptions: _jsx(StandardCollectionExtraHeaderOptions, { tableId: reverseRelationId, collectionId: collectionId, collectionType: collectionType, reverseCollectionProps: {
                    recordId: referenceRecordId,
                    standardRelation: standardRelation,
                    standardCollectionComponent: standardCollectionComponent,
                }, type: "reverseCollection" }), moreOptionsDropdown: moreOptionsDropdown, scheduleColorCoding: colorCoding, schedulePublishShift: schedulePublishShift, canSwitchViewType: !standardCollectionComponent }) }));
};
export default React.memo(TableOptionHeaderFromReverseCollection);
