var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { appSessionState, orgListState } from 'src/state';
import { useAccountApi } from '../api';
import { useAppSession } from '../component';
var useAccount = function (args) {
    var isAuthenticated = useAuth0().isAuthenticated;
    var _a = useAppSession(), createSessionForAccount = _a.createSessionForAccount, fetchAccountDetailsForSession = _a.fetchAccountDetailsForSession;
    var _b = useAccountApi(), initUser = _b.initUser, getAccountList = _b.getAccountList;
    var _c = useRecoilState(orgListState), organizations = _c[0], setOrganizations = _c[1];
    var appSession = useRecoilValue(appSessionState);
    var initializeAccount = function () { return __awaiter(void 0, void 0, void 0, function () {
        var orgs, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 9, , 10]);
                    if (!isAuthenticated) return [3 /*break*/, 8];
                    // This is what actually registers a user on the BE if they haven't been assigned an auth user id yet
                    // or their open id hasn't been added to an existing auth user
                    return [4 /*yield*/, initUser()
                        // Once you've done this, you can safely proceed with hitting other endpoints
                        // Get all accounts the user has access to (BE uses your session to find all of your orgs)
                    ];
                case 1:
                    // This is what actually registers a user on the BE if they haven't been assigned an auth user id yet
                    // or their open id hasn't been added to an existing auth user
                    _a.sent();
                    return [4 /*yield*/, getAccountList()
                        // If the initiated user has at least one org, proceed with processing their session
                    ];
                case 2:
                    orgs = _a.sent();
                    if (!(orgs && (orgs === null || orgs === void 0 ? void 0 : orgs.length) > 0)) return [3 /*break*/, 7];
                    if (!(appSession === null || appSession === void 0 ? void 0 : appSession.accountId)) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetchAccountDetailsForSession(appSession.accountId)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 4: 
                // Otherwise we need to create a new session for them
                return [4 /*yield*/, createSessionForAccount(orgs[0].accountId)];
                case 5:
                    // Otherwise we need to create a new session for them
                    _a.sent();
                    return [4 /*yield*/, fetchAccountDetailsForSession(orgs[0].accountId)];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7:
                    setOrganizations(orgs !== null && orgs !== void 0 ? orgs : []);
                    _a.label = 8;
                case 8: return [3 /*break*/, 10];
                case 9:
                    e_1 = _a.sent();
                    // This effectively signals that we tried to fetch the user's orgs but failed
                    // and the conditions in LandingPage should render new account onboarding
                    setOrganizations([]);
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        /**
         * skipInit is passed as an arg to the hook to handle cases when useAccount is called in
         * multiple components that get mounted and rendered on page load at the same time (an unfortunate hack to
         * avoid duplicate requests on page load)
         */
        if (args === null || args === void 0 ? void 0 : args.skipInit)
            return;
        if (!organizations) {
            initializeAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizations]);
    return {
        isFetchingOrg: organizations === undefined,
        organizations: organizations !== null && organizations !== void 0 ? organizations : [],
        fetchAccountDetailsForSession: fetchAccountDetailsForSession,
    };
};
export default useAccount;
