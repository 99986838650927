var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockCircleOutlined, EditOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import CustomCell from 'src/components/Cell/CustomCell';
import { SchemaWithNewValuesType } from 'src/components/RecordDetailModal/RecordDetailModal';
import { TriggerPropertyType, ValueDataType, ValueDataTypeTriggerPropMap } from 'src/shared';
import { useTimeZone } from 'src/hooks/component';
import { getMutateSchemaProperties, getSpecialMutateSchemaProperties } from 'src/services';
import { AutomationDynamicVariableSelect, AutomationTriggerPropertySelect, } from '../BuilderComponents';
import { OffsetType, } from '../Interface';
import { automationBaseCollectionIdState } from '../atom';
import ModifyTiming from '../BuilderComponents/ModifyTiming';
var SelectorTypeReference;
(function (SelectorTypeReference) {
    SelectorTypeReference["DYNAMIC"] = "dynamicValues";
    SelectorTypeReference["VALUE"] = "inputSchemaProperties";
    SelectorTypeReference["TRIGGER"] = "triggerProperties";
})(SelectorTypeReference || (SelectorTypeReference = {}));
var CreateRecordSchemaRow = function (_a) {
    var _b, _c, _d;
    var schema = _a.schema, index = _a.index, actionObject = _a.actionObject, updateAction = _a.updateAction;
    var detail = actionObject.detail;
    var relativeTimeValue = ((_b = detail.relativeTimeValues) !== null && _b !== void 0 ? _b : []).find(function (r) { return r.schemaId === schema.id; });
    var replaceRelativeTimeValue = function (relativeTimeValue) {
        var _a;
        var newRelativeTimeValues = __spreadArray(__spreadArray([], ((_a = detail.relativeTimeValues) !== null && _a !== void 0 ? _a : []).filter(function (r) { return r.schemaId !== relativeTimeValue.schemaId; }), true), [
            relativeTimeValue,
        ], false);
        updateAction(__assign(__assign({}, actionObject), { detail: __assign(__assign({}, detail), { relativeTimeValues: newRelativeTimeValues }) }));
    };
    var baseCollectionId = useRecoilValue(automationBaseCollectionIdState);
    var _e = useState(detail.dynamicValues &&
        ((_c = detail.dynamicValues) === null || _c === void 0 ? void 0 : _c.findIndex(function (dynamicValue) {
            return schema.id === dynamicValue.schemaId;
        })) !== -1), isDynamicValue = _e[0], setIsDynamicValue = _e[1];
    var _f = useState(detail.triggerProperties &&
        ((_d = detail.triggerProperties) === null || _d === void 0 ? void 0 : _d.findIndex(function (triggerProperty) {
            return schema.id === triggerProperty.schemaId;
        })) !== -1), isTriggerProperty = _f[0], setIsTriggerProperty = _f[1];
    var schemaContent = {
        id: schema.id,
        name: schema.name,
        inputMode: schema.inputMode,
        type: schema.type,
        nativePath: schema.nativePath,
        refCollectionId: schema.refCollectionId,
        canEdit: true,
        canOnlyUpdateEmpty: false,
        isRequired: false,
        isDefaultHidden: false,
        isQuickCreate: false,
        preventVisibilityConfiguration: false,
    };
    var timeZone = useTimeZone().timeZone;
    var findValueIdxMatchingSchema = useCallback(function (arrayToCheck) {
        return arrayToCheck === null || arrayToCheck === void 0 ? void 0 : arrayToCheck.findIndex(function (value) { return value.schemaId === schema.id; });
    }, [schema.id]);
    var existingSchemaPropIndex = useMemo(function () {
        return findValueIdxMatchingSchema(detail.inputSchemaProperties);
    }, [detail.inputSchemaProperties, findValueIdxMatchingSchema]);
    var existingDynamicValueIndex = useMemo(function () {
        var _a;
        return (_a = findValueIdxMatchingSchema(detail.dynamicValues)) !== null && _a !== void 0 ? _a : -1;
    }, [detail.dynamicValues, findValueIdxMatchingSchema]);
    var existingTriggerPropIndex = useMemo(function () {
        var _a;
        return (_a = findValueIdxMatchingSchema(detail.triggerProperties)) !== null && _a !== void 0 ? _a : -1;
    }, [detail.triggerProperties, findValueIdxMatchingSchema]);
    var onEditSchemaValue = function (value) {
        var tempActionObj = cloneDeep(actionObject);
        var tempActionDetail = tempActionObj.detail;
        var schemaProperty = schemaContent.type == ValueDataType.FILE
            ? getSpecialMutateSchemaProperties([
                {
                    schema: schemaContent,
                    newValues: value,
                    type: SchemaWithNewValuesType.SPECIAL,
                },
            ])[0]
            : getMutateSchemaProperties([
                {
                    schema: schemaContent,
                    newValues: value,
                    type: SchemaWithNewValuesType.NORMAL,
                },
            ], timeZone)[0];
        var isNonEmptyValue = !!value[0];
        // Add/Replace/Remove new schema property
        if (existingSchemaPropIndex != -1) {
            if (isNonEmptyValue) {
                tempActionDetail.inputSchemaProperties.splice(existingSchemaPropIndex, 1, schemaProperty);
            }
            else {
                tempActionDetail.inputSchemaProperties.splice(existingSchemaPropIndex, 1);
            }
        }
        else if (isNonEmptyValue) {
            tempActionDetail.inputSchemaProperties.push(schemaProperty);
        }
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
    };
    var updateActionDetailArray = function (actionDetailArray, existingIdx, newValue) {
        if (!actionDetailArray) {
            actionDetailArray = [];
        }
        if (existingDynamicValueIndex !== -1) {
            actionDetailArray.splice(existingIdx, 1, newValue);
        }
        else {
            actionDetailArray.push(newValue);
        }
        return actionDetailArray;
    };
    var onSelectDynamicValue = function (_val, elem) {
        var tempActionObj = cloneDeep(actionObject);
        var tempActionDetail = tempActionObj.detail;
        var dynamicValue = elem.parentSchemaId
            ? {
                type: elem.type,
                schemaId: schema.id,
                dynamicSchemaId: elem.parentSchemaId,
                dynamicSecondarySchemaId: elem.optionId,
            }
            : {
                type: elem.type,
                schemaId: schema.id,
                dynamicSchemaId: elem.optionId,
                dynamicSecondarySchemaId: null,
            };
        tempActionDetail.dynamicValues = updateActionDetailArray(tempActionDetail.dynamicValues, existingDynamicValueIndex, dynamicValue);
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
    };
    var onSelectTriggerProperty = function (_val, elem) {
        var tempActionObj = cloneDeep(actionObject);
        var tempActionDetail = tempActionObj.detail;
        var triggerProperty = { type: elem.key, schemaId: elem.value };
        tempActionDetail.triggerProperties = updateActionDetailArray(tempActionDetail.triggerProperties, existingTriggerPropIndex, triggerProperty);
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
    };
    var getSelectorContexts = useCallback(function () {
        return [
            {
                valuesRef: SelectorTypeReference.DYNAMIC,
                valueIndex: existingDynamicValueIndex,
                setter: setIsDynamicValue,
                isSet: isDynamicValue,
            },
            {
                valuesRef: SelectorTypeReference.VALUE,
                valueIndex: existingSchemaPropIndex,
                setter: undefined,
                isSet: undefined,
            },
            {
                valuesRef: SelectorTypeReference.TRIGGER,
                valueIndex: existingTriggerPropIndex,
                setter: setIsTriggerProperty,
                isSet: isTriggerProperty,
            },
        ];
    }, [
        existingDynamicValueIndex,
        existingSchemaPropIndex,
        existingTriggerPropIndex,
        isDynamicValue,
        isTriggerProperty,
    ]);
    var changeSelector = useCallback(function (selectorValuesRef) {
        var _a;
        var newActObj = cloneDeep(actionObject);
        var newActDetail = newActObj.detail;
        var selectors = getSelectorContexts();
        var selectorGroups = Object.groupBy(selectors, function (_a) {
            var valuesRef = _a.valuesRef;
            return valuesRef == selectorValuesRef ? 'thisSelector' : 'otherSelectors';
        });
        if (selectorGroups.thisSelector.length !== 1) {
            throw RangeError('There should be exactly 1 selector matching selectorType');
        }
        var thisSelector = selectorGroups.thisSelector[0];
        var otherSelectors = selectorGroups.otherSelectors;
        if (newActDetail[thisSelector.valuesRef] &&
            thisSelector.isSet &&
            thisSelector.valueIndex !== -1) {
            // Clear existing selector value when toggling it off
            (_a = newActDetail[thisSelector.valuesRef]) === null || _a === void 0 ? void 0 : _a.splice(thisSelector.valueIndex, 1);
        }
        else if (!thisSelector.isSet) {
            // Clear other selector values when toggling this selector on
            otherSelectors.forEach(function (sel) {
                var _a;
                if (sel.valueIndex !== -1) {
                    (_a = newActDetail[sel.valuesRef]) === null || _a === void 0 ? void 0 : _a.splice(sel.valueIndex, 1);
                }
            });
        }
        newActObj.detail = newActDetail;
        updateAction(newActObj);
        if (thisSelector.setter !== undefined) {
            thisSelector.setter(!thisSelector.isSet);
        }
        // Clicking one selector, on or off, turns all others off. If all false, we
        // use the default value selector
        otherSelectors.forEach(function (s) { return s.setter && s.setter(false); });
    }, [actionObject, getSelectorContexts, updateAction]);
    var toggleIsDynamicValue = useCallback(function () { return changeSelector(SelectorTypeReference.DYNAMIC); }, [changeSelector]);
    var toggleIsTriggerProperty = useCallback(function () { return changeSelector(SelectorTypeReference.TRIGGER); }, [changeSelector]);
    var _g = useMemo(function () {
        var defaultCellValue;
        var defaultFileValue;
        detail.inputSchemaProperties.forEach(function (property) {
            var _a;
            if (property.schemaId === schema.id) {
                defaultFileValue = (_a = property.collectionValue) === null || _a === void 0 ? void 0 : _a.files;
                defaultCellValue = property.collectionValue
                    ? [
                        property.collectionValue[property.collectionValue.type],
                    ]
                    : property.uuids
                        ? property.uuids
                        : [
                            property.name ||
                                property.text ||
                                property.number ||
                                property.amount ||
                                property.email ||
                                property.phone ||
                                property.uuid ||
                                (property.timestamp !== undefined
                                    ? String(property.timestamp)
                                    : property.boolean !== undefined
                                        ? String(property.boolean)
                                        : property[property.type]),
                        ];
            }
        });
        return { defaultCellValue: defaultCellValue, defaultFileValue: defaultFileValue };
    }, [detail.inputSchemaProperties, schema.id]), defaultCellValue = _g.defaultCellValue, defaultFileValue = _g.defaultFileValue;
    var cellProps = {
        schema: schemaContent,
        editCell: onEditSchemaValue,
        canEdit: true,
        updateWithDefault: true,
        defaultValue: defaultCellValue,
        defaultFileValues: defaultFileValue,
    };
    var clearSelectors = useCallback(function () {
        var newActObj = cloneDeep(actionObject);
        var newActDetail = newActObj.detail;
        var selectors = getSelectorContexts();
        var changed = false;
        selectors.forEach(function (sel) {
            var _a;
            // Clear value arrays (triggerProperties/dynamicValues/etc)
            if (newActDetail[sel.valuesRef] && sel.valueIndex !== -1) {
                (_a = newActDetail[sel.valuesRef]) === null || _a === void 0 ? void 0 : _a.splice(sel.valueIndex, 1);
                changed = true;
            }
            // Unset selectors
            if (sel.isSet && sel.setter) {
                sel.setter(false);
            }
        });
        if (changed) {
            updateAction(newActObj);
        }
    }, [actionObject, getSelectorContexts, updateAction]);
    var isAutomationDetermined = useMemo(function () {
        return schema.refCollectionId && schema.refCollectionId == baseCollectionId;
    }, [baseCollectionId, schema.refCollectionId]);
    useEffect(function () {
        if (isAutomationDetermined) {
            clearSelectors();
        }
    }, [isAutomationDetermined, clearSelectors]);
    var triggerTypes = ValueDataTypeTriggerPropMap[schema.type];
    var getTriggerPropertyButtonIcon = useCallback(function () {
        // If there are more than 1 triggerType for this schema, provide a generic gear button
        if ((triggerTypes === null || triggerTypes === void 0 ? void 0 : triggerTypes.length) && (triggerTypes === null || triggerTypes === void 0 ? void 0 : triggerTypes.length) > 1) {
            return _jsx(SettingOutlined, {});
        }
        // Otherwise, choose specific button style that matches the (only) triggerType
        switch (triggerTypes === null || triggerTypes === void 0 ? void 0 : triggerTypes[0]) {
            case TriggerPropertyType.ACTOR:
                return _jsx(UserOutlined, {});
            default:
                return _jsx(SettingOutlined, {});
        }
    }, [triggerTypes]);
    var getSelectElement = function () {
        var selectorElement = (_jsx("div", { className: "inputValueWrapper", children: isAutomationDetermined || relativeTimeValue ? (_jsx("div", { className: "lockedSchemaInputWrapper", children: _jsx("p", { children: isAutomationDetermined ? "Automation Determined" : "Relative" }) })) : (_jsx(CustomCell, __assign({}, cellProps))) }));
        if (isDynamicValue) {
            selectorElement = (_jsx(AutomationDynamicVariableSelect, { value: existingDynamicValueIndex !== -1
                    ? detail.dynamicValues[existingDynamicValueIndex]
                    : undefined, onSelect: onSelectDynamicValue, type: "action" }));
        }
        else if (isTriggerProperty) {
            selectorElement = (_jsx(AutomationTriggerPropertySelect, { currentValue: existingTriggerPropIndex !== -1
                    ? detail.triggerProperties[existingTriggerPropIndex]
                    : undefined, triggerTypes: triggerTypes, schema: schema, onSelect: onSelectTriggerProperty }));
        }
        return selectorElement;
    };
    return (_jsxs("div", { className: "clear-both", children: [_jsxs("div", { className: "schemaSection", children: [_jsx("p", { className: "schemaLabel", children: schema.name }), getSelectElement(), !isAutomationDetermined && (_jsx(Tooltip, { title: "Toggle dynamic values", mouseEnterDelay: 0.2, children: _jsx(Button, { onClick: toggleIsDynamicValue, className: "ant-btn ant-btn-primary m-[8px] min-w-[32px]", icon: _jsx(EditOutlined, {}) }) })), !isAutomationDetermined && triggerTypes && (_jsx(Tooltip, { title: "Toggle trigger properties", mouseEnterDelay: 0.2, children: _jsx(Button, { onClick: toggleIsTriggerProperty, className: "ant-btn ant-btn-primary m-[8px] min-w-[32px]", icon: getTriggerPropertyButtonIcon() }) })), !isAutomationDetermined &&
                        (schema.type === ValueDataType.DATETIME ||
                            schema.type === ValueDataType.NATIVE_DATETIME) && (_jsx(Tooltip, { title: "Toggle relative timing", mouseEnterDelay: 0.2, children: _jsx(Button, { onClick: function () {
                                var _a, _b, _c;
                                var newAction = __assign(__assign({}, actionObject), { detail: __assign(__assign({}, detail), { inputSchemaProperties: detail.inputSchemaProperties.filter(function (p) { return p.schemaId !== schema.id; }), relativeTimeValues: ((_a = detail.relativeTimeValues) !== null && _a !== void 0 ? _a : []).find(function (r) { return r.schemaId === schema.id; })
                                            ? ((_b = detail.relativeTimeValues) !== null && _b !== void 0 ? _b : []).filter(function (r) { return r.schemaId !== schema.id; })
                                            : __spreadArray(__spreadArray([], ((_c = detail.relativeTimeValues) !== null && _c !== void 0 ? _c : []), true), [
                                                {
                                                    schemaId: schema.id,
                                                    offset: 0,
                                                    offsetType: OffsetType.MINUTES,
                                                },
                                            ], false) }) });
                                updateAction(newAction);
                            }, className: "ant-btn ant-btn-primary m-[8px] min-w-[32px]", icon: _jsx(ClockCircleOutlined, {}) }) }))] }, 'schemaSection-' + index), relativeTimeValue && (_jsx("div", { className: "float-right mb-2", children: _jsx(ModifyTiming, { triggerOffset: relativeTimeValue.offset, setTriggerOffset: function (triggerOffset) {
                        if (triggerOffset !== null) {
                            replaceRelativeTimeValue(__assign(__assign({}, relativeTimeValue), { offset: triggerOffset }));
                        }
                    }, isOffsetDelayed: null, setIsOffsetDelayed: function () { }, canTriggerBefore: null, offsetType: relativeTimeValue.offsetType, setOffsetType: function (offsetType) {
                        if (offsetType !== null) {
                            replaceRelativeTimeValue(__assign(__assign({}, relativeTimeValue), { offsetType: offsetType }));
                        }
                    }, min: 0, hideClose: true }) }))] }));
};
export default React.memo(CreateRecordSchemaRow);
