import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { Select } from 'antd';
import { useRecoilValue } from 'recoil';
import { automationFilterOptionsState } from '../atom';
import { AutomationDynamicValueType } from '../Interface';
import { onSelectFilterOption } from '../util';
var Option = Select.Option, OptGroup = Select.OptGroup;
var AutomationDynamicVariableSelect = function (_a) {
    var value = _a.value, onSelect = _a.onSelect, type = _a.type;
    var filterOptions = useRecoilValue(automationFilterOptionsState);
    var selectRef = useRef(null);
    var onSelectWithBlur = function (val, elem) {
        var _a;
        onSelect(val, elem);
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    };
    return (_jsx("div", { className: "dynamicVariableSelector", children: _jsxs(Select, { ref: selectRef, className: "Selector", placeholder: "Insert Variable", onSelect: onSelectWithBlur, value: value
                ? "".concat(value.dynamicSecondarySchemaId
                    ? "".concat(value.dynamicSchemaId).concat(value.dynamicSecondarySchemaId)
                    : value.dynamicSchemaId, "-").concat(value.type)
                : undefined, showSearch: true, filterOption: onSelectFilterOption, dropdownMatchSelectWidth: false, children: [type === 'action' && (_jsxs(OptGroup, { label: "Automation Determined", children: [_jsx(Option, { value: "null-".concat(AutomationDynamicValueType.RECORD), optionId: null, label: "Triggering Record", name: "Triggering Record", type: AutomationDynamicValueType.RECORD, children: "Triggering Record" }, AutomationDynamicValueType.RECORD), _jsx(Option, { value: "null-".concat(AutomationDynamicValueType.ACTOR), optionId: null, label: "Triggering Actor", name: "Triggering Actor", type: AutomationDynamicValueType.ACTOR, children: "Initiating User" }, AutomationDynamicValueType.ACTOR)] }, "Automation Determined")), filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.map(function (group, groupIdx) { return (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option, idx) {
                        var _a;
                        var label = group.parentSchemaId !== null
                            ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                            : "".concat(option.name);
                        return (_jsx(Option, { value: "".concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id, "-").concat(group.type), optionId: option.id, label: label, name: option.name, parentSchemaId: group.parentSchemaId, parentSchemaName: group.parentSchemaName, type: group.type, children: label }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx, "-").concat(option.id, "-").concat(label, "-").concat(idx)));
                    }) }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx))); })] }) }));
};
export default React.memo(AutomationDynamicVariableSelect);
